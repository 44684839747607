/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        $('a.nav-link').not( '.jump' ).on('click touchend', function(e) {
          var el = $(this);
          var link = el.attr('href');
          window.location = link;
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        $( '.panel-placeholder' ).remove();

        // Add mdl button classes to form submit
        // $( '.submit-wrap' ).find( 'input[type="button"]' ).addClass( 'mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent' );
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        var TypingEvents = (function() {

          // Type text vars
          var typeWrap = document.getElementById( 'textbox' ),
            typeNode = document.getElementById( 'typed' ),
            triggers = Array.prototype.slice.call( document.getElementsByClassName( 'draw-line' ) ),
            strings = document.getElementsByClassName( 'hover-text' ),
            trigText,
            writers = [],
            typeQueue = [],
            autoTrigger = true,
            slideTimerId,
            loopTimerId,
            lineTimerId,
            intervalId,
            pauseTimerId,
            idx,
            typeRate = 17,
            breakDelay = 700,
            baseTime = 700,
            loopDelay = 1000,
            cTyper;

          // Line object
          var ListItem = {
            isTyped: false,
            idxIntervalId: 0,
            cText: '',
            toType: [],
            setRef: function( listItem ) {
              this.ref = listItem;
            },
            setDataRef: function( parentData ) {
              this.dataRef = parentData;
            },
            setLineContainer: function() {
              this.lineContainer = document.createElement( 'li' );
            },
            setParts: function() {

              if ( !this.ref.hasChildNodes() ) { return false; }

                var itemNodes = Array.prototype.slice.call( this.ref.childNodes ),
                  partsArr = [];

                // set ref for parts
                for (var n = 0; n < itemNodes.length; n++) {
                  var part = itemNodes[n];
                  part.ref = part;
                  partsArr.push( part );

                  if ( this.isTextPart( part.ref ) ) {
                    this.setCharQueue( part.ref.nodeValue.split( '' ) );
                  }

                }

                this.parts = partsArr;

            },
            isTextPart: function( part ) {
              return ( part.ref.nodeType === 3 );
            },
            setCharQueue: function( charArray ) {
              this.charQueue = charArray;
            },
            getCharQueue: function() {
              return this.charQueue;
            },
            setQueueTime: function( qTime ) {
              this.queueTime = qTime;
            },
            getQueueTime: function() {
              return this.queueTime;
            },
            setTypeRate: function( typeRate ) {
              this.typeRate = typeRate;
            },
            getTypeRate: function() {
              return this.typeRate;
            }
          };
            
          function createLine( listItem, parentData ) {

            var Line = Object.create( ListItem );

            Line.setTypeTimes = function() {

              var tRate = ( baseTime / this.charQueue.length );
              this.setTypeRate( tRate );

              var qTime = ( ( baseTime ) + ( baseTime * .5 ) );
              this.setQueueTime( qTime );

              // return this.queueTime;
            };
            
            Line.setupLine = function() {
              this.setRef( listItem );
              this.setDataRef( parentData );
              this.setLineContainer();
              this.setParts();
              this.getCharQueue();
              this.setTypeTimes();
            };

            Line.setToType = function( toType ) {
              this.setCharQueue( toType );
            };
            
            Line.setupLine();
            
            return Line;

          }

          // Typedata object
          var Typedata = {
            typeQueue: [],
            typedText: '',
            setTypingState: function( state ) {
              this.isTyping = state;
            },
            setAuto: function( state ) {
              this.autoType = state;
            },
            setStartState: function( state ) {
              this.startTyping = state;
            },
            setContainer: function( container ) {
              this.listContainer = container;
            },
            setLines: function( typeLines ) {
              this.lines = typeLines;
            },
            getLines: function() {
              return this.lines;
            },
            setRate: function( typeRate ) {
              this.rate = typeRate;
            },
            getRate: function() {
              return this.rate;
            },
            setLineDelay: function( breakDelay ) {
              this.lineDelay = breakDelay;
            },
            getLineDelay: function() {
              return this.lineDelay;
            },
            setLoopDelay: function( loopDelay ) {
              this.loopDelay = loopDelay;
            },
            getLoopDelay: function() {
              return this.loopDelay;
            },
            setData: function( trigText ) {

              if ( this.isTyping ) { return; }

              if ( this.startTyping ) {

                this.trigText = trigText;
                this.target = document.getElementById( this.trigText.dataset.typetarget );
                
                var listItems = Array.prototype.slice.call( this.target.getElementsByTagName( 'li' ) ),
                  linesArr = [];

                if (listItems.length === 0) { return; }

                this.setContainer( document.createElement( 'ul' ) );

                // create new lines
                for (var nItem = 0; nItem < listItems.length; nItem++) {

                  var Line = createLine( listItems[nItem], this.trigText.dataset.typetarget );

                  linesArr.push( Line );
                }

                this.setLines( linesArr );

                this.setStartState( false );

                return true;

              } else if ( !this.startTyping ) {

                typeQueue.push( trigText );
                this.setQueue( typeQueue );

                // return true;
              }

            },
            clearData: function() {
              this.trigText = '';
              this.target = '';
              this.lines = [];
            },
            setQueue: function( typeQueue ) {
              this.typeQueue = typeQueue;
            },
            getQueue: function() {
              return this.typeQueue;
            },
            nextLine: function() {
              if ( this.lines.length < 0 ) { return false; }
              return this.lines.shift();
            },
            typeLine: function( Line, typeAndStop ) {
              // type a single line
              if ( !ListItem.isPrototypeOf( Line ) ) { return; }

              if ( !Line.hasOwnProperty('parts') || Line.isTyped ) { return true; }

              var cPart, exitLine = false, nPart = 0;

              while ( nPart < Line.parts.length ) {

                cPart = Line.parts[nPart];

                if ( typeAndStop && Line.isTextPart( cPart ) ) {
                
                  cTyper.listContainer.appendChild( Line.lineContainer );

                  exitLine = true;
                  Line.isTyped = true;
                  Line.toType = Line.getCharQueue();

                  $( Line.lineContainer ).delay( Line.queueTime * 0.42 ).animate({
                    opacity: 1
                  }, {
                    duration: Line.queueTime
                  });

                }

                nPart++;

                if ( exitLine ) { return false; }

              }

              return true;

            },
            setLoopTimer: function( loopDelay ) {

              if ( this.isTyping || !this.autoType ) { return; }

              loopDelay = loopDelay || this.getLoopDelay();

              var tId = writers.indexOf(this);

              idx = ( (tId + 1) < writers.length ) ? tId + 1 : 0;

              loopTimerId = setTimeout( function() {

                autoType( idx );

                clearTimeout( loopTimerId );

                return;

              }, loopDelay );

            },
            setLineTimer: function( breakDelay, isAll ) {

              if ( this.isTyping || ( !this.lines.length > 0 ) ) { return; }

              breakDelay = breakDelay || this.getLineDelay();

              this.setTypingState( true );

              $( typeNode ).empty();
              $( typeWrap ).stop().fadeIn( 'fast' );
              typeNode.appendChild( this.listContainer );

              cTyper = this;

              lineTimerId = setTimeout( function() {

                if ( isAll ) {
                  cTyper.startAll();
                } else {
                  cTyper.start();
                }

                cTyper.setStartState( false );

                cTyper.trigText.classList.add( 'hover' );

                clearTimeout( lineTimerId );

              }, breakDelay );

            },
            start: function() {

              // typeRate = typeRate || cTyper.getRate();

              var nLine = cTyper.lines.shift();

              cTyper.setRate( nLine.getTypeRate() );

              intervalId = setInterval( function() {

                cTyper.typeWrite( nLine );

                return; // <-- ??

              }, cTyper.getRate() );

            },
            startAll: function() {

              typeRate = typeRate || cTyper.getRate();

              cTyper.lines.forEach( function( element, index ) {

                var nLine = cTyper.lines[index];

                cTyper.setRate( nLine.getTypeRate() );

                nLine.idxIntervalId = setInterval( function() {

                  cTyper.typeWrite( nLine );

                  //return; // <-- ??

                }, typeRate );

              });

            },
            typeWrite: function( cLine ) {

              // shift chars into text until returns false; clear
              if ( cTyper.typeLine( cLine, true ) && cLine.toType.length === 0 ) {

                clearInterval( cLine.idxIntervalId );
                cTyper.stop();
                cLine.cText = '';
                cLine.toType = [];
                return; 
              }

              cLine.cText += cLine.toType.shift();
              $( cLine.lineContainer ).text( cLine.cText );
              cLine.setCharQueue( cLine.toType );

            },
            stop: function() {
              // clear timers and temporary typing vars and set timer for next line item
              clearInterval( intervalId );

              this.setTypingState( false );
              
              if ( (this.lines.length > 0) && this.autoType) {

                clearTimeout( lineTimerId );

                this.setLineTimer( breakDelay, false );

              } else {

                clearTimeout( lineTimerId );
              }

              if ( (this.lines.length === 0 ) && this.autoType ) {
                pauseTimerId = setTimeout(function(){
                  cTyper.clean( cTyper.setLoopTimer() );
                }, 500);
              }

            },
            clean: function( callback, arg ) {
              // clear timers, temporary typing vars, and typed text; remove active class from triggers
              clearInterval( intervalId );
              clearTimeout( lineTimerId );
              clearTimeout( pauseTimerId );

              triggers.forEach( function( element ) {
                element.classList.remove( 'hover' );
              });

              this.setContainer( null );
              this.setAuto( true );
              this.setTypingState( false );
              this.setStartState( true );
              this.lines = [];

              this.setData( this.trigText );

              this.emptyTyped( callback, arg );

            },
            emptyTyped: function( callback, arg ) {

              $( typeWrap ).fadeOut( 600, function() {

                $( typeNode ).empty();
                $( typeWrap ).show();

                if ( callback ) {
                  ( arg === null ) ? callback() : callback( arg );
                }

                return;

              });

            }
          };

          // Create a Typewriter
          function createTypewriter( trigText ) {

            var Typewriter = Object.create( Typedata );

            Typewriter.startQueue = function( breakDelay, isAll ) {
            
              triggers.forEach( function( element ) {
                element.classList.remove( 'hover' );
              });
              
              this.setLineTimer( breakDelay, isAll );
            };

            Typewriter.setAutoType = function( autoTrigger, loopDelay ) {
              this.setAuto( autoTrigger );
              this.setLoopDelay( loopDelay );
            };

            Typewriter.stopQueue = function() {
              this.stop();
            };

            Typewriter.cleanTyping = function( callback, arg ) {
              this.clean( callback, arg );
            };

            Typewriter.resetTyping = function() {
              this.setContainer( null );
              this.setAuto( true );
              this.setTypingState( false );
              this.setStartState( true );
              this.lines = [];

              this.setData( this.trigText );
            };

            Typewriter.hoverInterrupt = function() {

              clearInterval( intervalId );
              clearTimeout( lineTimerId );
              clearTimeout( loopTimerId );

              $( typeNode ).empty();
              $( typeWrap ).stop().fadeIn( 'fast' );

              writers.forEach( function( element, index ) {
                var cWriter = writers[index];
                cWriter.trigText.classList.remove( 'hover' );
                if ( cWriter.isTyping ) {
                  cWriter.resetTyping();
                }
              });

            };

            Typewriter.setTypeData = function() {
              this.setAuto( true );
              this.setTypingState( false );
              this.setStartState( true );
              this.setRate( typeRate );
              this.setLineDelay( breakDelay );
              this.setLoopDelay( loopDelay );
              this.setData( trigText );

              var typeWriter = this;

              $( trigText ).on( 'mouseover', function( event ) {
                typeWriter.hoverInterrupt();
                typeWriter.setAutoType( false );
                typeWriter.startQueue( 0, true );
              });

              $( trigText ).on( 'mouseout', function( event ) {
                var tId = writers.indexOf( typeWriter );
                idx = ( (tId + 1) < writers.length ) ? tId + 1 : 0;
                typeWriter.cleanTyping( autoType, idx );
              });

            };

            Typewriter.setTypeData();

            return Typewriter;

          }

          // Create an array of new Typewriters from each text span that should trigger typing
          function setWriters() {

            triggers.forEach( function( element, index ) {

              var trigText = triggers[index];

              writers.push( createTypewriter( trigText ) );

            });

            return writers;
          }

          function autoType( index ) {

            var cWriter, index;

            cWriter = writers[index];
            cWriter.setAutoType( true, 1000 );
            cWriter.startQueue( breakDelay, false );

          }

          function init() {
            initEvents();
          }

          function initEvents() {

            setWriters();

            slideTimerId = setTimeout( function() {

              $( '.intro-text' ).removeClass( 'pushed' );
              clearTimeout(slideTimerId);
              autoType( 0 );

            }, 500);

          }

          return { init : init };

        })();

        (function() {
          TypingEvents.init();
        }());

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Contact us page
    'contact_us': {
      init: function() {
        // JavaScript to be fired on the about us page
        
        // Email mailto links
        var mailToNY = (function() {
          var username = "NYOffice";
          var hostname = "invus.com";
          var linktext = "mail" + "to:" + username + "@" + hostname ;
          $( '.email-ny' ).attr( 'href', linktext );
        })();

        var mailToEU = (function() {
          var username = "EUOffice";
          var hostname = "invus.com";
          var linktext = "mail" + "to:" + username + "@" + hostname ;
          $( '.email-eu' ).attr( 'href', linktext );
        })();

        var mailToRecruit = (function() {
          var username = "recruiting";
          var hostname = "invus.com";
          var linktext = "mail" + "to:" + username + "@" + hostname ;
          $( '.email-recruit' ).attr( 'href', linktext );
        })();
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
