// TODO: move this into theme files (wp_nav_menu filter)
// Add class to menu nav links
$( '.menu-item' ).children().addClass( 'nav-link' );

var NavEvents = (function() {

	// vars
	var breakpoint = 928,
		navListItems = $( '.menu-item' ),
		navLinkItems = $( '.nav-link' ),
		menuBtn = document.getElementsByClassName( 'menu-toggle' ),
		menuBtnIcon = document.getElementsByClassName( 'menu-toggle-icon' ),
		menuList = $( '#primary-menu' ),
		mainNav = document.getElementById( 'site-navigation' ),
		mainNavItems = mainNav.querySelectorAll( '.nav-link' ),
		navArray = Array.prototype.slice.call( mainNavItems ),
		header = $( 'header.banner' ),
		inViewMargin = ( $( 'header.banner' ).height() + $( '.header-border' ).height() ),
		openMenu = $( '.menu-open' ),
		navPusher = $( '.nav-pusher' ),
		siteContainer = $( '.site-container' ),
		stickyTarget = $( '#navbar' ),
		offsetTop = stickyTarget.offset().top,
		moreLinks = Array.prototype.slice.call( document.getElementsByClassName( 'more-link' ) ),
		wrap = '<div class="nav-wrapper" style="height: ' + inViewMargin + 'px;"></div>',
		mId;

	// Check if screen size is smaller than the main breakpoint
	var isSmallScreen = function() {
		return ( window.innerWidth < breakpoint );
	};

	// Check if nav is toggled
	var isToggled = function() {
		var navToggled = $( '#site-navigation' ).hasClass( 'toggled' );
		return navToggled;
	};

	// Check if nav is sticky
	var isSticky = function() {
		return stickyTarget.hasClass( 'sticky' );
	};

	// Sticky navbar on scroll
	var stickyNav = function() {
		var scrollTop = $( window ).scrollTop();

		if ( scrollTop > offsetTop ) {
			siteContainer.addClass( 'is-stuck' );
			if ( isSmallScreen() && !stickyTarget.parent().hasClass( 'nav-wrapper' ) ) {
				stickyTarget.wrap( wrap );
			}
			stickyTarget.addClass( 'sticky' );
		} else {
			siteContainer.removeClass( 'is-stuck' );
			if ( isSmallScreen() && stickyTarget.parent().hasClass( 'nav-wrapper' ) ) {
				stickyTarget.unwrap();
			}
			stickyTarget.removeClass('sticky');
		}

	};

	var toggleMenu = function() {

		// Toggle menu and button icon		
		if ( isToggled() ) {

			// close panel stuff
			$( mainNav ).removeClass( 'toggled' );
			$( menuBtn ).attr( 'aria-expanded', 'false' );
			siteContainer.removeClass( 'menu-open' );

		} else {

			// open panel stuff
			$( mainNav ).addClass( 'toggled' );
			$( menuBtn ).attr( 'aria-expanded', 'true' );
			siteContainer.addClass( 'menu-open' );

		}

	};

	function init() {
		initEvents();
	}

	function initEvents() {

		if ( isSmallScreen() ) {
			// Sticky navbar on scroll
			stickyNav();
		}

		// scroll events
		$( window ).scroll(function() {

			if ( isSmallScreen() ) {
				stickyNav();
			}

			if ( isSmallScreen() && isToggled() ) {
				$( menuBtn ).trigger( "click" );
			}

		});	

		// open / close dropdown menu when menu icon is clicked
		$( menuBtn ).on( "click", function() {			
			toggleMenu();			
		});

		// close the menu dropdown when the 'X' icon is clicked
		$( '.menu-item-close' ).on( 'click', function( event ) {
			event.preventDefault();
			$( menuBtn ).trigger( "click" );
		});

		// adjustments
		$( window ).resize( function() {

			if ( isSmallScreen() ) {
				stickyNav();
			}
				
			if ( isToggled() ) {
				$( menuBtn ).trigger( "click" );
			}

		});

		// Smooth scroll to same page links
		$( 'a.jump[href*="#"]:not([href="#"])' ).on( 'click', function( event ) {
			event.preventDefault();

			var headerOffset = ( header.height() + 40 ),
				targElem = $( this.hash );

			if ( !targElem ) { return false; }

			var scrollTarget = ( isSmallScreen() ) ? ( targElem.offset().top - headerOffset ) : ( targElem.offset().top );
			$( window ).scrollTo( scrollTarget, 1000 );	

			return false;

		});

	}

	return { init : init };

})();

(function() {
	NavEvents.init();
}());
